
.ExplorerControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ExplorerControls .controlButtons {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
}

.ExplorerControls .controlButtons button {
  margin-right: 15px;
}

.ExplorerControls .screenshotsCounts {
  color: var(--mutedTextColor);
  font-size: 0.85rem;
  margin: 20px 0 0;
}