.RunParametersDefinition .ETLschemaButtons button {
  margin-right: 20px;
}

.RunParametersDefinition .ETLparameters {
  margin-top: 30px;
}

.RunParametersDefinition .ETLparametersContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}


.ETLParamDefinition {
  width: 49%;
  background: var(--elevatedBackground);
  /* margin: 5px 5px; */
  padding: 15px 20px 15px 15px;
  border-radius: 4px;
  transition: background 0.2s, color 0.2s;
}

.ETLParamDefinition header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ETLParamDefinition h4 {
  margin: 0 10px 0 0;
  font-size: 1rem;
}

.ETLParamDefinition .ETLparam {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.ETLParamDefinition span.ETLparamName {
  width: 200px;
}

.ETLParamDefinition .ParamDefinitionInput {
  width: 50px;
}

.ETLParamDefinition .ParamDefinitionInput input {
  text-align: right;
}

.ETLParamDefinition .rangeSlider {
  width: 95%;
  margin: 10px auto;
}

.ETLParamDefinition .description {
  display: block;
  width: 90%;
}

.ETLParamDefinition .helperText {
  display: block;
  width: 90%;
  margin: 10px 0;
}