.fileSelectorSection {
  background: var(--sectionBackground);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  float: left;
  width: 49%;
  height: 250px;
  border: 1px dashed var(--pronouncedBackground);
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.fileSelectorSection.active {
  border: 2px dashed #69c576;
}
.fileSelectorSection.active button {
  background: #69c576;
}

.fileSelectorSection button:hover {
  background: #69c576;
}
