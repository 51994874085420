.DownloadData {
  display: flex;
  justify-content: space-between;
}

.DownloadData > span.downloadProgressText {
  margin: 5px 5px 0 0;
  font-size: 0.8rem;
}

.DownloadData .progressBar {
  width: 130px;
  margin: 10px 0 0 5px;
}

.DownloadData > .runDetails {
  margin: 5px 10px;
}

.DownloadData > button {
  height: 30px;
}

.downloadFileButton {
  font-size: smaller;
}
