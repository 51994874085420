.CategoryDefinitions {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.BulkCategoryDefinition {
	width: 100%;
}

.ListCategoryDefinition {
	width: 46.5%;
}

.BulkCategoryDefinition h3, .ListCategoryDefinition h3 {
	margin-top: 5px;
}