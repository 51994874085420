.simpleTable {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
}

.simpleTable p {
  margin-bottom: 0;
}
.simpleTable label {
  margin-bottom: 0;
}

.simpleTable thead {
  margin: 5px;
}

.simpleTable thead th {
  padding-bottom: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.simpleTable td {
  text-align: center;
  padding: 2px 0;
}
