.weeklyReportTable tr {
  line-height: 4px;
}

.weeklyReportTable tr.totalRow {
  font-weight: 800;
}

.weeklyReportTable th {
  box-shadow: 1px 1px 0 0 rgba(255, 255, 255, 0.15) !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.weeklyReportTable td {
  box-shadow: 1px 1px 0 0 rgba(255, 255, 255, 0.15) !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.weeklyReportTable td.rowHeader {
  font-weight: 800;
}

.weeklyReportTable td.negative {
  color: var(--intent-danger) !important;
}
