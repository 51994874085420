.loginPage {
  height: 100vh;
  color: #e6e9ed;
  background: #30404d;
}

.loginPageBody {
  display: flex;
  justify-content: center;
  padding-top: 200px;
  font-size: 1.2rem;
}

footer.buildNumber {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.7rem;
  font-style: italic;
  padding: 10px;
}