.lineChartsContainer {
  margin: 20px 5px 0;
  /* display: flex;
  justify-content: space-between; */
}
.lineChartsContainer .fullWidth {
  width: 100%;
}

.lineChartsContainer .left-column {
  float: left;
  /* display: block; */
  width: 49%;
  /* margin-right: 20px; */
}
.lineChartsContainer .right-column {
  float: right;
  /* display: block; */
  width: 49%;
  /* margin-right: 20px; */
}

.lineChart {
  margin-bottom: 10px;
  position: relative;
}

/* .lineChart > .lineChartActions {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  margin: 0 5px 10px 0;
} */

.lineChart button.copyToClipboard {
  display: none;
  position: absolute;
  z-index: 10;
  bottom: 10px;
  right: 30px;
}
