.fileToUploadRowSection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: var(--elevatedBackground);
  margin: 10px 5px;
  padding: 10px;
  border-radius: 5px;
}

.fileToUploadRowSection p {
  margin-bottom: 0;
}

.fileToUploadRowSection .fileName {
  margin-left: 10px;
  flex-grow: 5;
}

.fileToUploadRowSection .fileSize {
  margin-right: 10px;
}

.fileToUploadRowSection button {
  margin-left: 10px;
}