.uploadButton,
.removeButton,
.deleteButton {
  padding: 7px 7px;
  margin-left: 10px;
  color: white;
  border: none;
  border-radius: 4px;
  background: #5c7080;
}

.uploadButton:disabled,
.removeButton:disabled,
.deleteButton:disabled {
  background: #a7b6c2;
  cursor: not-allowed;
}

.uploadButton:disabled:hover,
.removeButton:disabled:hover,
.deleteButton:disabled:hover {
  background: #a7b6c2;
}

.removeButton:hover,
.deleteButton:hover {
  background: red;
}

.uploadButton:hover {
  background: #69c576;
}

.bigButton {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 75px;
  color: white;
  border: none;
  border-radius: 10px;
  opacity: 0.8;
}

.bigButton:hover {
  opacity: 1;
}
