.fileListTable {
  background: var(--sectionBackground);
  border: solid 1px var(--pronouncedBackground);
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.fileListTable thead tr th {
  padding: 10px 0;
  border-bottom: solid 1px var(--dividerColor);
}

.fileListTable tbody tr:hover {
  background: var(--elevatedBackground);
}

.fileListTable tbody {
  padding: 0 10px;
}

.fileListTable tbody tr td {
  padding: 5px 0;
}

.fileListTable tr:not(:first-child) td {
  border-top: solid 1px var(--elevatedBackground);
}
