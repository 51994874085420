body.bp3-dark {
  --appBackground: #30404d;
  --elevatedBackground: #394b59;
  --pronouncedBackground: #4a5d6c;
  --cardBackground: #3e4c5c;
  --sectionBackground: #293742;
  --sideBarBackground: #202b33;
  --primaryTextColor: #f5f8fa;
  --mutedTextColor: #a7b6c2;
  --disabledTextColor: #778793;
  --dividerColor: #778793;
  --linkTextColor: #0f6ba3;
  --shadow: #30404d;
  --iconFill: #a7b6c2;
  --intent-danger: #db3737;
  transition: background 0.2s, color 0.2s;
}
