
.dialogContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 95%;
  background-color: rgba(0,0,0,0) !important;
  margin-top: 50px;
}

.screenshotDialog {
  margin-right: 20px;
}

.screenshotDialog:last-child {
  margin-right: 0;
}

.screenshotDialog img {
  max-width: 100%;
}

.screenshotDialog h6 {
  color: #F5F8FA;
  text-align: center;
  margin-top: -25px;
}