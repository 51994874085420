.PricesChart > .chartActions {
  position: absolute;
  z-index: 10;
  /* bottom: 0; */
  right: 0;
  margin: -40px 30px 0 0;
}

.PricesChart button.copyToClipboard {
  margin: -2px 5px 0;
}
