.CategoryParamsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.NumberOfItemsDefinition {
  height: 75px;
}

.ParamDefinition {
  width: 49%;
  background: var(--elevatedBackground);
  margin: 5px 5px;
  padding: 12px 8px 8px;
  border-radius: 4px;
}

.ParamDefinition header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ParamDefinition h4 {
  margin: 0 10px 0 0;
}

.ParamDefinition .ParamDefinitionInput {
  width: 50px;
}

.ParamDefinition .ParamDefinitionInput input {
  text-align: right;
}

.ParamDefinition .rangeSlider {
  width: 95%;
  margin: 10px auto;
}

.ParamDefinition .description {
  display: block;
  width: 90%;
}

.ParamDefinition .helperText {
  display: block;
  width: 90%;
  margin: 10px 0;
}
