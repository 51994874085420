.categoryParamsTable {
	width: 100%;
	border: none;
}

.categoryParamsTable tbody td {
	border: none;
}

.categoryParamsTable thead tr th {
	text-align: center;
	vertical-align: middle;
	padding-bottom: 10px;
	border-bottom: solid 1px var(--dividerColor);	
}

.categoryParamsTable th.name {
	width: 50%;
}

.categoryParamsTable tbody .parameterValueInput {
  width: 90px;
  margin: 0 auto;
}

.categoryParamsTable tbody .parameterValueInput input {
	text-align: center;
}

.categoryParamsTable tbody td {
	text-align: center;
}

.categoryParamsTable tbody tr {
  height: 40px
}

.addItemSection {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.addItemSection .addItemSectionInput {
	width: 200px;
	margin: 0 10px;
}