#breadcrumbs {
  font-size: 1rem;
  display: inline-block;
  margin: 10px;
  padding: 6px 10px;
  background: var(--pronouncedBackground);
  border: 1px solid #c0ccd6;
  border-radius: 3px;
  transition: background 0.2s, color 0.2s;
}

#breadcrumbs a {
  color: var(--linkTextColor) !important;
}

#breadcrumbs .chevron-right {
  color: var(--linkTextColor);
  position: relative;
  top: -2px;
  padding: 0 5px;
}


.bp3-dark #breadcrumbs {
  border: 1px solid #27343F;
}

.bp3-dark #breadcrumbs a {
  color: var(--primaryTextColor) !important;
}

.bp3-dark #breadcrumbs .chevron-right {
  color: var(--mutedTextColor);
}