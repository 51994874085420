
.CategoryDefinition .CategoryParamsContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.CategoryDefinition h3 {
	margin: 0;
}

.CategoryDefinition button {
	display: block;
}