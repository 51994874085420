.paramDialog .parametersContent {
  margin: 20px 20px 0;
}

.paramDialog .top-row {
  display: flex;
  justify-content: space-between;
}

.paramDialog .top-row .pageTypeSelector {
  display: flex;
  justify-items: flex-end;
  margin-top: -5px;
}

.paramDialog .top-row .pageTypeSelector p {
  margin-right: 10px;
  margin-top: 5px;
}

.paramDialog .parametersContent .bp3-switch {
  margin-bottom: 20px;
}

.paramDialog .parametersContent .bp3-form-group {
  margin-bottom: 5px;
  margin-bottom: 25px;
}

.paramDialog .parametersContent .bp3-form-group.invalid {
  margin-bottom: 5px;
}

.paramDialog .parametersContent .parametersContent_left {
  width: 350px;
  float: left;
}

.paramDialog .parametersContent .parametersContent_right {
  width: 380px;
  float: right;
}

.paramDialog .parametersContent input {
  width: 100%;
}

.paramDialog .parametersContent .buttons {
  clear: both;
  float: right;
}

.paramDialog .parametersContent .buttons button {
  margin-left: 10px;
}