.OrbitalDataTable tr {
  line-height: 4px;
}

.OrbitalDataTable tr.totalRow {
  font-weight: 800;
  background-color: var(--elevatedBackground);
}

.OrbitalDataTable th {
  box-shadow: 1px 1px 0 0 rgba(255, 255, 255, 0.15) !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.OrbitalDataTable td {
  box-shadow: 1px 1px 0 0 rgba(255, 255, 255, 0.15) !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.OrbitalDataTable th.rowHeader {
  font-weight: 800;
  background-color: var(--elevatedBackground);
}

.OrbitalDataTable td.columnHeader {
  text-align: left !important;
  font-weight: 800;
}

.OrbitalDataTable td.negative {
  color: var(--intent-danger) !important;
}
