.topRow {
  margin: 5px;
  /* display: flex;
  justify-content: space-between; */
}

.topRow .options {
  float: left;
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
}

.topRow .options .timeGrouping,
.topRow .options .priorYears,
.topRow .options .toggleTable {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}

.topRow .options .toggleCumulative {
  margin-top: 5px;
}

.topRow .options .toggleCumulative label {
  margin-bottom: 0;
}

.topRow .options .timeGrouping h5,
.topRow .options .priorYears h5,
.topRow .options .toggleTable h5 {
  margin: 8px 10px 0 0;
}

.topRow .options .priorYears h5 {
  width: 100px;
}

.dataContainer {
  float: right;
  display: flex;
  justify-content: right;
  /* margin-top: -50px; */
}

.dataContainer > span.downloadProgressText {
  margin: 5px 5px 0 0;
  font-size: 0.8rem;
}

.dataContainer .progressBar {
  width: 130px;
  margin: 10px 0 0 5px;
}

.dataContainer > .runDetails {
  margin: 5px 10px;
}

.dataContainer > button {
  height: 30px;
}

.dataContainer .dataParametersButton {
  margin: 0 15px;
}

.dataParametersContent {
  margin: 20px;
}

.filtersLoading {
  clear: both;
  display: flex;
  justify-content: flex-start;
  background: var(--elevatedBackground);
  padding: 25px;
  width: 100%;
}
.filtersLoading p {
  margin: 0 0 0 10px;
}

.filters {
  clear: both;
  display: flex;
  justify-content: space-between;
}

.filters > section {
  margin: 0 5px;
  flex: 10;
}

.filters > section.Park {
  flex: 1;
}

.filters > section h4 {
  margin-bottom: 5px;
}
