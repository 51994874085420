@import url('AppThemes/DefaultTheme.css');
@import url('AppThemes/DarkTheme.css');

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&display=swap');

/*******************************
*****   GENERAL SETTINGS   *****
*******************************/
body {
  overscroll-behavior: none;
  font-family: 'Open Sans', 'Helvetica Neue', 'sans-serif';
  background: var(--appBackground);
}

body h2 {
  margin-bottom: 10px;
}

.App button {
  cursor: pointer;
}

.App a:hover {
  text-decoration: none;
}