.filesToUploadSection {
  background: var(--sectionBackground);
	float: right;
	margin-right: 3px;
	height: 250px;
	width: 50%;
  border: solid 1px var(--pronouncedBackground);
  border-radius: 5px;
	overflow: scroll;
  transition: background 0.2s, color 0.2s;
}