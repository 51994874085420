
.ExplorerListTable {
  background: var(--sectionBackground);
  border: solid 1px var(--pronouncedBackground);
  border-radius: 5px;
  margin-top: 10px;
  transition: background 0.2s, color 0.2s;
}

.ExplorerListTable thead tr th {
  padding: 10px 0;
  border-bottom: solid 1px var(--dividerColor);
}

.ExplorerListTable tbody tr:hover {
  background: var(--elevatedBackground);
}

.ExplorerListTable tbody {
  padding: 0 10px;
}

.ExplorerListTable tbody tr td {
  padding: 0;
  height: 35px;
}

.ExplorerListTable tbody tr td:last-child {
  padding-right: 10px;
}

.ExplorerListTable tbody tr td p {
  padding: 5px 0;
}

.ExplorerListTable tbody tr td:hover {
  cursor: pointer;
}

.ExplorerListTable tr:not(:first-child) td {
  border-top: solid 1px var(--elevatedBackground);
}

.ExplorerListTable {
  width: 100%;
  table-layout: fixed;
  overflow-wrap: break-word;
}

.ExplorerListTable p {
  margin-bottom: 0;
}
.ExplorerListTable label {
  margin-bottom: 0;
}

.ExplorerListTable thead {
  margin: 5px;
}

.ExplorerListTable thead th {
  padding-bottom: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.ExplorerListTable td {
  text-align: center;
  padding: 2px 0;
}

/****************************
*****   Loading state   *****
****************************/

.ExplorerListTable tbody.loading tr td {
  height: 200px;
  width: 100%;
}