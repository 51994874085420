.datasetsHeader {
display: flex;
justify-content: space-between;
}

.datasetListTable {
  background: var(--sectionBackground);
  border: solid 1px var(--pronouncedBackground);
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.datasetListTable thead tr th {
  padding: 10px 0;
  border-bottom: solid 1px var(--dividerColor);
}

.datasetListTable tbody tr:hover {
  background: var(--elevatedBackground);
}

.datasetListTable tbody {
  padding: 0 10px;
}

.datasetListTable tbody tr td {
  padding: 5px 0;
}

.datasetListTable tr:not(:first-child) td {
  border-top: solid 1px var(--elevatedBackground);
}

.datasetsButtons {
  margin-top: 10px;
}

.datasetsButtons button {
  margin-right: 10px;
}