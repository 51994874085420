.PricesFilters {
  clear: both;
  display: flex;
  justify-content: space-between;
}

.PricesFilters > div.dateFilters, .PricesFilters > section {
  flex: 1;
}

.PricesFilters > section {
  margin-left: 10px
}

.PricesFilters .dateFilters section {
  margin: 0 0 5px;
  min-width: 300px;
  display: flex;
  /* justify-content: space-between; */
}

.PricesFilters .dateFilters section.primaryDateFilter button {
  margin-right: 86px;
}

.PricesFilters .dateFilters section.additionalDateFilter .bp3-html-select {
  width: 143px;
}

.PricesFilters h4 {
  margin-bottom: 5px;
}

.PricesFilters .dateFilters {
  display: flex;
  flex-direction: column;
}