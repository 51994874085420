
.data-table h4 {
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.data-table table {
  font-size: 0.8rem;
  width: 100%;
  border: 1px solid var(--pronouncedBackground);
  margin-bottom: 25px;
}

.data-table table th, .data-table table td {
  text-align: center; 
  vertical-align: middle;
}

.data-table table th:first-child, .data-table table td:first-child {
  width: 90px;
}