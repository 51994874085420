.RunDefinition {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 200px;
}

.RunDefinition > * {
  margin: 20px;
}

.RunDefinition .runStatus {
  width: 400px;
  margin-left: 0;
  display: flex;
  justify-content: left;
  align-items: center;
}

.RunDefinition .runStatus > span {
  margin-left: 10px;
}

.RunDefinition .runStatus span.runStatusMessage {
  font-size: 0.8rem;
  font-style: italic;
}