.weeklyReports {
  margin: 10px;
}

.weeklyReports .topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.weeklyReports .topRow .leftGroup,
.weeklyReports .topRow .rightGroup {
  display: flex;
  gap: 2rem;
}

.downloadEmailFileButton {
  font-size: smaller;
}

.weeklyReports .topRow .leftGroup .filters h4 {
  display: none;
}

.reportDate > h5 {
  white-space: nowrap;
  margin: 0 10px 0;
}

.reportDate {
  display: flex;
  align-items: center;
  margin-top: -10px;
}
