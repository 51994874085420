.DataLoader {
  margin: 10px;
}
.DataLoader section.titleRow {
  display: flex;
  justify-content: space-between;
}

.DataLoader section.topRow {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.DataLoader article.controls section {
  margin: 5px 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem 1rem;
}

.DataLoader article.controls section > span {
  margin-top: 6px;
  width: 65px;
  font-size: 0.8rem;
}

.DataLoader article.refreshData {
  min-width: 430px;
}

.DataLoader main {
  margin-top: 20px;
}
