.bp3-tab {
  color: var(--primaryText);
}

[aria-selected="true"], .bp3-tab:not([aria-disabled="true"]):hover {
  color: #106ba3;
}

.DatasetConfiguration header {
  display: flex;
  justify-content: space-between;
}

.DatasetConfiguration header .rightSideButtons > * {
  float: left;
  margin-left: 10px;
}

.DatasetConfiguration header .rightSideButtons .bp3-switch {
  margin-top: 7px;
}