.Navbar .Heading a {
  color: #f3f8fa;
}

.Navbar .menuLink a {
  color: #bbcdd7;
}

.Navbar .menuLink a:hover {
  color: #f3f8fa;
}

.Navbar ul {
  list-style: none;
  padding-left: 20px;
}

.Navbar li {
  float: left;
  margin-right: 10px;
}

.Navbar li.menuLink a {
  padding: 10px;
}

.Navbar .darkModeButton {
  margin-right: 10px;
  color: var(--primaryBackground)
}