.chartsContainer {
  margin: 20px 5px 0;
  /* display: flex;
  justify-content: space-between; */
}

/* .chartGroup {
  display: block;
  width: 49%;
  margin-right: 20px;
} */
.chartsContainer .fullWidth {
  width: 100%;
}

.chartsContainer .left-column {
  float: left;
  /* display: block; */
  width: 49%;
  /* margin-right: 20px; */
}
.chartsContainer .right-column {
  float: right;
  /* display: block; */
  width: 49%;
  /* margin-right: 20px; */
}

.chart {
  margin-bottom: 10px;
  position: relative;
}

.chart > .chartActions {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  margin: 0 5px 10px 0;
}

.chart button.copyToClipboard {
  margin: -2px 5px 0;
}
