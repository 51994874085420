.FieldsConfiguration .importExportButtons {
  margin: 20px 10px 10px 0;
  display: flex;
  justify-content: flex-end;
}

.FieldsConfiguration .importExportButtons button {
  margin-left: 10px;
}

.FieldsConfiguration .closeButton {
  margin: 10px 10px 0 0;
  display: flex;
  justify-content: flex-end;
}

.fieldsConfigurationTable {
  background: var(--sectionBackground);
  border: solid 1px var(--pronouncedBackground);
  border-radius: 5px;
  margin: 10px;
  width: calc(100% - 20px);
}

.fieldsConfigurationTable thead tr th {
  padding: 10px 0;
  border-bottom: solid 1px var(--dividerColor);
}

.fieldsConfigurationTable tbody tr:hover {
  background: var(--elevatedBackground);
}

.fieldsConfigurationTable tbody {
  padding: 0 10px;
}

.fieldsConfigurationTable tbody tr td {
  padding: 5px 0;
}

.fieldsConfigurationTable tr:not(:first-child) td {
  border-top: solid 1px var(--elevatedBackground);
}
