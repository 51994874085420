.inputFilesTable {
  font-size: 0.8rem;
  background: var(--sectionBackground);
  border: solid 1px var(--pronouncedBackground);
  border-radius: 5px;
  transition: background 0.2s, color 0.2s;
}

.inputFilesTable thead tr th {
  padding: 10px 0;
  border-bottom: solid 1px var(--dividerColor);
}

.inputFilesTable tbody tr:hover {
  background: var(--elevatedBackground);
}

.inputFilesTable tbody {
  padding: 0 10px;
}

.inputFilesTable tbody tr td {
  padding: 5px 0;
}

.inputFilesTable tr:not(:first-child) td {
  border-top: solid 1px var(--elevatedBackground);
}
